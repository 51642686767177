@use "sass:math";
$WoodSmoke: #0F1010;
$coolGrey: #9D9F9E;
$bodyBgColor: #F0F0F0;
$themeInfoColor: #226AE8;
$thickBlue: #1F2DF1;
$vistaWhite: #F8F8F8;
$panelBgColor: #ffffff;
$darkTextColor: #000001;
$lightGreen: #F0FFF9;
$themeSuccess: #54D69E;
$lightPurple: #FCEFFF;
$barneyPurple: #8C26AA;
$aquaHaze: #E6F4F5;
$darkCyan: #008DA9;
$themeYellow: #DFAC00;
$lightYellow: #F3EFE5;
$fieldBorder: #E3E3E3;
$iceberg: #DCEFE8;
$borderColor: #E7E7E7;
$bleachedCedar: #252727;
$paretGreen: #0BFFA6;
$grayBorder: #DCDCDC;


//Font Names
$fontExtraBold: "NunitoSans-ExtraBold";
$fontBold: "NunitoSans-Bold";
$fontSemiBold: "NunitoSans-SemiBold";
$fontRegular: "NunitoSans-Regular";


@mixin fontExtraBold {
  font-family: $fontExtraBold;
}

@mixin fontBold {
  font-family: $fontBold;
}

@mixin fontSemiBold {
  font-family: $fontSemiBold;
}

@mixin fontRegular {
  font-family: $fontRegular;
}



$fieldBorderRadius: 0.25rem;

$scut-rem-base: 16 !default; // default 16px size

$timeUnits: s !default; // s stands for seconds

//Mixins
@mixin panel {
  background-color: $panelBgColor;
  border: 1px solid $borderColor;
  padding: 30px;
}

@mixin formField {
  border: 1px solid $fieldBorder;
  padding: 0.813rem;
  border-radius: $fieldBorderRadius;
  min-height: 2.5rem;
  font-family: $fontRegular;
}

//(rotate(0deg), scale(1))
@mixin rotate($rotate, $scale) {
  -webkit-transform: rotate($rotate) scale($scale);
  -ms-transform: rotate($rotate) scale($scale);
  transform: rotate($rotate) scale($scale);
}

//@button( background-color, color, padding, font-size)
@mixin button($bgColor, $color, $padding, $fontsize) {
  padding: $padding;
  font-size: $fontsize;
  display: inline-block;
  border-radius: $fieldBorderRadius;
  background-color: $bgColor;
  color: $color;
  line-height: 1.5;
  border: 0;
  transition: 0.5s all ease;
}

@mixin mainContent() {
  flex: 1;
  // box-shadow: 0 0px 0px 0px $borderColor, 0 0px 0px 0px $borderColor,
  //   16px 5px 13px -21px rgba($borderColor, 0.55),
  //   -16px 5px 14px -21px rgba($borderColor, 0.55);
  min-height: calc(100vh - 35vh);
}

@mixin transition($time) {
  -webkit-transition: $time + $timeUnits;
  transition: $time + $timeUnits;
  -moz-transition: $time + $timeUnits;
}

@function scut-strip-unit($num) {
  // Md Sarmad - node-sass is replaced with sass and "/" is being deprecated in latest upgrade
  @return math.div($num, ($num * 0 + 1));
}

@function scut-rem($pixels) {
  // Md Sarmad - node-sass is replaced with sass and "/" is being deprecated in latest upgrade
  @return math.div(scut-strip-unit($pixels), $scut-rem-base) * 1rem;
}

@mixin fontSize($size) {
  @if $size != null {
    font-size: scut-rem($size);
  }
}

// Text Ellipsis
@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
