@import './containers/variables';
@import './containers/reset';
@import './containers/flex';

@font-face {
  font-family: "NunitoSans-Bold";
  src: url("./assets/fonts/ttf/NunitoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-ExtraBold";
  src: url("./assets/fonts/ttf/NunitoSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: url("./assets/fonts/ttf/NunitoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-SemiBold";
  src: url("./assets/fonts/ttf/NunitoSans-SemiBold.ttf") format("truetype");
}



html,
body {
  margin: 0;
  padding: 0;
}

* {
  direction: ltr;
}


body {
  font-size: 14px;
  background-color: $panelBgColor;
  color: $WoodSmoke;
  @include fontBold;
}

textarea {
  resize: none;
}

.panel {
  @include panel;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.w-100 {
  width: 100%;
}

.displyInline {
  display: inline;
}
.displyBlock {
  display: block;
}
.displayContent {
  display: contents;
}
.displayInBlock {
  display: inline-block;
}
.displayNone {
  display: none;
}
.alignSuper {
  vertical-align: super;
}
.alignSub {
  vertical-align: sub;
}
.alignBottom {
  vertical-align: bottom;
}
.w-50 {
  width: 50%;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.5rem;
}
.p-2 {
  padding: 1rem;
}
.p-3 {
  padding: 1.5rem;
}
.p-4 {
  padding: 2rem;
}
.p-5 {
  padding: 2.5rem;
}
.p-1-2 {
  padding: 1rem 2rem;
}
.p-2-1 {
  padding: 2rem 1rem;
}
.pl-1 {
  padding-left: 0.5rem;
}
.pl-2 {
  padding-left: 1rem;
}
.pl-3 {
  padding-left: 1.5rem;
}
.pl-4 {
  padding-left: 2rem;
}
.pl-5 {
  padding-left: 2.5rem;
}
.pr-1 {
  padding-right: 0.5rem;
}
.pr-2 {
  padding-right: 1rem;
}
.pr-3 {
  padding-right: 1.5rem;
}
.pr-4 {
  padding-right: 2rem;
}
.pr-5 {
  padding-right: 2.5rem;
}
.pt-1 {
  padding-top: 0.5rem;
}
.pt-2 {
  padding-top: 1rem;
}
.pt-3 {
  padding-top: 1.5rem;
}
.pt-4 {
  padding-top: 2rem;
}
.pt-5 {
  padding-top: 2.5rem;
}
.pb-1 {
  padding-bottom: 0.5rem;
}
.pb-2 {
  padding-bottom: 1rem;
}
.pb-3 {
  padding-bottom: 1.5rem;
}
.pb-4 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}
.p-02 {
  padding: 0 2rem;
}
.px-05 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.pt-0 {
  padding-top: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.m-1 {
  margin: 0.5rem;
}
.m-2 {
  margin: 1rem;
}
.m-3 {
  margin: 1.5rem;
}
.m-4 {
  margin: 2rem;
}
.m-04 {
  margin: 0 2rem;
}
.m-5 {
  margin: 2.5rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.ml-2 {
  margin-left: 1rem;
}
.ml--0 {
  margin-left: 0px;
}
.ml-3 {
  margin-left: 1.5rem;
}
.ml-4 {
  margin-left: 2rem;
}
.ml-5 {
  margin-left: 2.5rem;
}
.ml--1 {
  margin-left: -1rem;
}
.mr--1 {
  margin-right: -1rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mr-2 {
  margin-right: 1rem;
}
.mr-3 {
  margin-right: 1.5rem;
}
.mr-4 {
  margin-right: 2rem;
}
.mr-5 {
  margin-right: 2.5rem;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 2rem;
}
.mt-5 {
  margin-top: 2.5rem;
}
.mt--1 {
  margin-top: -1rem;
}
.mt--3 {
  margin-top: -3rem;
}
.mt--5 {
  margin-top: 5px;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}
.mb--3 {
  margin-bottom: -1.5rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.mb-5 {
  margin-bottom: 2.5rem;
}
.px-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.m-0 {
  margin: 0;
}
.ml-0 {
  margin-left: 0;
}
.m-auto {
  margin: auto;
}
.m-0-auto {
  margin: 0 auto;
}
.mr-0 {
  margin-right: 0;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.textCapital {
  text-transform: capitalize;
}
a:focus {
  outline: none;
}

.borderBottom {
  border-bottom: 1px solid $grayBorder;
}

//flex items
.flex {
  @include row;
  [class^="col"] {
    position: relative;
    width: 100%;
    min-height: 1px;
  }
  .col4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.flexSb {
  @include row-spaceBetween;
}

.flexew {
  flex-grow: 1;
  flex-basis: 0;
}

.errorLabel {
  color: red($color: #000000);
  font-size: 0.875rem;
  line-height: 1.5;
  padding-top: 5px;
}
//Form Elements
.formControl {
  margin-bottom: 1.5rem;
  > label {
    display: block;
    color: $darkTextColor;
    line-height: 1.5;
    padding-bottom: 0.4rem;
    padding-top: 0rem;
    color: $coolGrey;
    @include fontRegular;
  }
  > .formField {
    @include formField;
    width: 100%;
    color: $darkTextColor;
    &:focus {
      border-color: rgba($borderColor, 0.7);
      box-shadow: 0px 0px 4px 1px rgba($borderColor, 0.4);
    }
  }
  .errorMessage {
    color: red;
    font-size: 0.875rem;
    line-height: 1.5;
    padding-top: 5px;
  }
}

.formControl {
  input[disabled] {
    background: $borderColor;
  }
}

.formControl {
  [class*=css-][class*=-control] {
    min-height: 50px;
    border-radius: 15px;
  }
}

input.formInput {
  border: none;
  padding: 14px;
  border-radius: 15px;
  min-height: 50px;
  @include fontSize(16);
  @include fontSemiBold;
  color: $WoodSmoke;
  background-color: $panelBgColor;
  transition: .5s;
}



input:hover,
input:focus-within,
.isBoxShadow {
  box-shadow: 0px 8px 10px #00000005;
}

.btn {  
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: $panelBgColor;
  border-radius: 10px;
  background-color: $panelBgColor;
  padding: 0 15px;
  @include fontSize(16);
  min-height: 44px;
  line-height: 14px;
  min-width: 100px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -moz-transition: 0.3s;
  @include fontBold;
  cursor: pointer;
}

.btn.secondaryButton {
  background-color: $thickBlue;
  &:hover {
    opacity: 0.8;
  }
}

.btn.btn-warning {
  background-color: #FFF4D9;
  border: 1px solid #FBD16B;
  color: #C79E39;
  &:hover {
    opacity: 0.8;
  }
}

.btn.btn-success {
  background-color: $paretGreen;
  color: $WoodSmoke;
  &:hover {
    opacity: 0.8;
  }
}


.isAnimation {
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.easeSmooth {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.duration-300 {
  transition-duration: 0.3s;
}
//button
.btn-link {
  @include button(transparent, $themeInfoColor, 0, 0.9rem);
  &:hover {
    text-decoration: underline;
  }
}
.btn-primary {
  @include button($coolGrey, $panelBgColor, 0.8rem 1rem, 1rem);
  min-width: 15rem;
  @include fontExtraBold;
  &:hover {
    opacity: 0.8;
  }
}

//Common html elements css

.errorMsgTitle {
  @include fontSize(18);
  @include fontExtraBold;
  color: $darkTextColor;
}

.Cursor {
  cursor: pointer;
}

select {
  padding: 0.5em 1em;
  height: 2.5em;
  width: 22.5em;
  border-radius: 4px;
  border: solid 1px $fieldBorder;
  color: $darkTextColor;
  font-size: 14px;
  @include fontRegular;
}
.disabledClass {
  pointer-events: none;
  background-color: $borderColor;
  color: $panelBgColor;
}
.enabledClass {
  color: red;
  font-size: 15px;
}

th {
  padding-bottom: 0.5em;
  text-align: left;
}
tr {
  height: 50px;
}

.borderRadius {
  border-radius: 4px;
}

.br-20 {
  border-radius: 20px;
}

.norightRadius {
  border-radius: 4px 0 0 4px;
}

.noRadius {
  border-radius: 0;
}

.noleftRadius {
  border-radius: 0 4px 4px 0;
}


.isFlex {
  @include row;
}

.isFlexColumn {
  @include column;
}

.isFlex.center,
.isFlexColumn.center {
  justify-content: center;
}

.isFlex.spacing,
.isFlexColumn.spacing {
  justify-content: space-between;
}

.isFlex.alignCenter,
.isFlexColumn.alignCenter {
  align-items: center;
}

.alignSelfCenter {
  align-self: center;
}

.isFlex.noWrap {
  flex-wrap: initial;
}

.alignStart {
  align-items: flex-start;
}

.alignEnd {
  align-items: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.transition {
  @include transition(0.3);
}
.transform {
  @include rotate(180deg, 1);
}

.upperCareText {
  text-transform: uppercase;
}

.linkColor {
  color: $themeInfoColor;
}

