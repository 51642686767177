.page-2 {
  background: transparent linear-gradient(180deg, #202623 0%, #0f1010 100%) 0%
    0% no-repeat padding-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-2 h2,
.page-2 h6 {
  color: #fff;
}

.page-2 h2 {
  font-size: 3rem;
  width: 25rem;
  text-align: center;
  position: relative;
  top: 8rem;
}

.page-2 .img-wrap img {
  position: relative;
  width: 30rem;
  left: -12rem;
}

.page-2 .img-wrap {
  position: relative;
  width: 50%;
}

.page-2 button {
  font-size: 0.9rem;
  padding: 0.5rem 1.4rem;
}

.page-2 h6 {
  font-size: 1.2rem;
  margin: 2rem 0;
}

.metal,
.basic {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.metal .line {
  position: absolute;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .page-2 .flex-between {
    flex-direction: column;
    gap: 2rem;
  }

  .page-2 h2 {
    top: 0;
    padding: 5rem 0;
    font-size: 2.5rem;
  }

  .page-2 .img-wrap img {
    width: 19rem;
    left: -7.5rem;
  }
}
