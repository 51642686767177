.address-form {
  margin: 3rem 5rem;
  position: relative;
}

.addr-bg {
  position: absolute;
  bottom: -1rem;
  right: 0;
  width: 38rem;
}

.address-form h6 {
  font-size: 1.5rem;
}

.address-form span {
  cursor: pointer;
  padding-bottom: 0.3rem;
}

.address-form span.home:hover {
  border-bottom: 1px solid #0f1010;
}

.address-form .input-select,
.address-form .input-wrap {
  width: 25rem;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .address-form {
    margin: 2rem;
  }

  .address-form .d-flex {
    flex-direction: column;
  }

  .addr-bg {
    display: none;
  }
}
