@import '../containers/variables';
@import '../containers/flex';

.isMainContainer {
    .side-nav {        
        position: sticky;
        top: 0;
        left: 0;
        display: block;
        z-index: 50;
        height: 100%;
        .isSidenavContainer {
            background: $panelBgColor;
            padding: 32px 10px;
            max-height: 100vh;
            min-height: 100vh;
            width: 120px;
            box-shadow: 2px 3px 10px #0000000D;
            .isLogo {
                margin-bottom: 45px;
                .brandTitle {
                    @include fontExtraBold;
                    @include fontSize(18);
                }
            }
            .sideNavMenu {
                ul {
                    li.navItems {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        margin: 25px 0;
                        .sideNavTitle {
                            @include fontSemiBold;
                            @include fontSize(16);
                            color: $coolGrey;
                            padding-top: 10px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    li.navItems.active {
                        .sideNavTitle {
                            color: $WoodSmoke;
                            @include fontBold;
                        }
                    }
                }
            }
        }
    }
}