.login-page {
  min-height: 100vh;
  background-color: var(--bg-primary);
  padding: 3rem 5rem;
}
.admin-btn {
  padding: 0.3rem 3rem;
}

.login_lady {
  width: 25rem;
  margin: auto;
  margin-right: 17rem;
}
.login_lady.otp {
  margin-right: 22rem;
}
.login-page h4 {
  font-size: 2rem;
  color: white;
}

.login-page p {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.login-page p span {
  color: white;
  opacity: 0.5;
}

.login-page p strong {
  opacity: 0.7;
  color: white;
}

.login-page p strong.primary-text {
  opacity: 1;
  color: #0bffa6;
}
.mobile_number {
  width: 80%;
}
.mobile {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.mobile input,
.input input {
  background: #0f1010 0% 0% no-repeat padding-box;
  /* border-top-left-radius: 8px; */
  border-bottom-right-radius: 8px;
  padding: 0.4rem 1rem;
  color: #fff;
  height: 4rem;
  border: none;
  width: 90%;
  font-size: 1.1rem;
}
.input input {
  margin-bottom: 0.5rem;
  width: 100%;
  border-radius: 8px;
}
.input input:focus,
.corporate .mobile input:focus {
  border: 1px solid #0bffa6;
}

.mobile input::placeholder,
.input input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.corporate .mobile_number {
  margin-top: 2rem;
}

.mobile span {
  background: #0f1010 0% 0% no-repeat padding-box;
  /* border-top-left-radius: 8px; */
  border-bottom-left-radius: 8px;
  display: block;
  padding: 0.4rem 1rem;
  color: #fff;
  font-weight: 600;
  height: 4rem;
  border: none;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}
.corporate .mobile input {
  border-top-right-radius: 8px;
}

.corporate .mobile {
  margin-bottom: 0.5rem;
}

.corporate .mobile span {
  border-top-left-radius: 8px;
}

.mobile_number .agree {
  color: white;
  opacity: 0.5;
  position: relative;
  top: -3px;
}

.login-btn,
.contact-btn {
  background-color: #0bffa6;
  width: 4rem;
  height: 3rem;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.contact-btn {
  width: 10rem;
  /* margin-top: 1rem; */
  font-weight: 700;
}

.contact-btn a {
  text-decoration: none;
  color: #101412;
}
.mrHalf {
  margin-right: 0.5rem;
}

.corporate-btn {
  background-color: #3427e1;
  height: 3rem;
  border-radius: 30px;
  color: #fff;
  margin-top: 4rem;
  border: none;
  padding: 0 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  position: relative;
  top: -3rem;
  left: -3rem;
}

.login-form.otp {
  left: -10rem;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5rem;
}

.terms .p-relative {
  display: flex;
  align-items: center;
}

.otp-terms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.otp-terms p {
  margin: 0 !important;
}

.otpInputBlock {
  display: flex;
  gap: 0.7rem;
  justify-content: center;
  margin: 3rem;
}
.otpInputField input {
  width: 3.5rem;
  height: 3.5rem;
  background-color: #101412;
  border: 1px solid #2b2b2b;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 1.2rem;
}

.otpInputField input:focus {
  border: 1px solid #0bffa6;
}

.bg-card-center {
  background-color: #101412;
  width: 30%;
  margin: auto;
  min-height: 500px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
}

.profile-pic {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
}

.bg-card-center p {
  color: #fff;
  margin-top: 1rem;
  font-size: 1.4rem;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .login-page .flex-between {
    flex-direction: column;
  }

  .bg-card-center {
    width: 80%;
  }

  .login-page {
    padding: 2rem;
  }

  .login-form.w40 {
    width: 100%;
  }

  .login-form {
    left: 0rem;
    top: 0rem;
  }

  .terms {
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile_number {
    width: 100%;
  }

  .mobile span {
    width: fit-content;
  }

  .login-btn {
    margin-top: 2rem;
  }

  .w30.otp {
    width: 100%;
  }
  .otp-terms {
    flex-direction: column;
    align-items: flex-start;
  }

  .login-form.otp {
    left: 0;
  }
}
