@import "../../containers/variables";
@import "../../containers/reset";
@import "../../containers/flex";

.content {
  background: $bodyBgColor;
  // padding: 32px 0px;
  width: 100%;
}

h1.title {
  @include fontExtraBold;
  color: $WoodSmoke;
  @include fontSize(25);
}
h3.title {
  @include fontBold;
  color: $WoodSmoke;
  @include fontSize(20);
}

.isDashboard {
  .isDashboardSections,
  .isSuperAdminDashboard {
    .leftPanel {
      width: 35%;
      .isOverviewCard {
        background: $panelBgColor;
        padding: 32px;
      }
    }
    .rightPanel {
      width: 63%;
    }
  }
}

.isOverviewBlock {
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 25px;
  .isCount {
    @include fontSize(26);
    @include fontExtraBold;
  }
  .isTitle {
    @include fontSize(20);
    padding-top: 25px;
  }
  &:nth-child(odd) {
    margin-right: 20px;
  }
}

.isOverviewBlock.noOfEmployees {
  border: 2px solid $themeSuccess;
  background: $lightGreen;
}

.isOverviewBlock.cardsIssued {
  border: 2px solid $barneyPurple;
  background: $lightPurple;
}

.isOverviewBlock.contactsAdded {
  border: 2px solid $darkCyan;
  background: $aquaHaze;
}

.isOverviewBlock.isNoEmployees {
  border: 2px solid $themeYellow;
  background: $lightYellow;
}

.isOverviewGraph {
  border: 1px solid $fieldBorder;
  background: $panelBgColor;
  height: 300px;
  padding: 25px;
}

.listOfContactsAdded {
  background: $panelBgColor;
  padding: 25px;
}

.listOfContactsAdded {
  .addedContact {
    width: 143px;
    height: 143px;
    border-radius: 10px;
    border: 1px solid $borderColor;
    background: $panelBgColor;
    position: relative;
    margin-right: 16px;
    margin-bottom: 20px;
    cursor: pointer;
    .isName {
      @include fontSize(16);
      color: $WoodSmoke;
      padding-top: 6px;
      text-align: center;
    }
    .isDesignation {
      @include fontSize(14);
      color: $coolGrey;
      padding-top: 6px;
      @include fontRegular;
    }
    .isAction {
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;
    }
    &:hover {
      box-shadow: 0px 16px 8px $borderColor;
      transition: 0.5s;
    }
  }
}

.isEmployeeDetails {
  .detailsHeader {
    .formGroup {
      position: relative;
      .formInput {
        padding-right: 70px;
        width: 450px;
      }
      .isIcons {
        position: absolute;
        right: 0;
        top: 15px;
      }
    }
    .btn.secondaryButton {
      border-radius: 30px;
    }
  }
}

.roundedBtn {
  border-radius: 40px;
  background-color: $panelBgColor;
  min-height: 50px;
  box-shadow: 0px 12px 10px #00000008;
  padding: 8px 14px;
  min-width: 160px;
  text-align: center;
  span {
    @include fontSize(16);
    @include fontExtraBold;
    color: $WoodSmoke;
    .isCount {
      color: $paretGreen;
      @include fontSize(24);
    }
  }
}

.progressButton {
  border-radius: 14px;
  background-color: $panelBgColor;
  min-height: 50px;
  box-shadow: 0px 12px 10px #00000008;
  padding: 8px 14px;
  border: 4px solid $paretGreen;
  min-width: 200px;
}

.isUpload {
  border-radius: 15px;
  background-color: $panelBgColor;
  min-height: 50px;
  padding: 5px 14px;
  min-width: 175px;
  span {
    color: $coolGrey;
  }
}

.isTotalEmployees {
  .searchByUser {
    position: relative;
    .formInput {
      border-radius: 25px;
      padding-right: 30px;
    }
    img {
      position: absolute;
      right: 10px;
      top: 4px;
    }
  }
  .viewType {
    img {
      &:first-child {
      }
    }
    .isTableview {
      position: relative;
      &::after {
        content: "";
        border-right: 1px solid #d1d1d1;
        height: 50px;
        width: 1px;
        position: absolute;
        right: 0;
        top: -15px;
      }
    }
  }
}

.isEmployeerDataTable {
  table {
    thead {
      tr {
        th {
          padding: 20px 15px;
          &:not(:first-child) {
            text-align: center;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 20px 15px;
          background-color: #f8f8f8;
          @include fontSemiBold;
          &:not(:first-child) {
            text-align: center;
          }
        }
        &:first-child {
          td {
            &:first-child {
              border-top-left-radius: 25px;
            }
            &:last-child {
              border-top-right-radius: 25px;
            }
          }
        }
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 25px;
            }
            &:last-child {
              border-bottom-right-radius: 25px;
            }
          }
        }
      }
    }
  }
}

.isEditProfile {
  .profileSection {
    width: 55%;
    .isEdit {
      color: $coolGrey;
      @include fontRegular;
      @include fontSize(18);
    }
    .isProfileForm {
      .isFormGrouping {
        .formControl {
          width: 50%;
          &:first-child {
            margin-right: 25px;
          }
        }
      }
    }
  }
  .profilePlan {
    width: 40%;
    background-color: $iceberg;
    .isPlanDetails {
      background-color: $panelBgColor;
      box-shadow: 0px 34px 22px #00000008;
      padding: 30px;
      border-radius: 20px;
      width: 300px;
      .planInfo {
        @include fontSemiBold;
        span {
          @include fontExtraBold;
          color: $themeInfoColor;
        }
      }
    }
  }
}

.isDesignationCardsList {
  .isDesignationCard {
    padding: 10px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0px 12px 15px #00000014;
    width: 248px;
    margin-right: 20px;
    margin-bottom: 25px;
    .btn.btn-success {
      border-radius: 25px;
      min-height: 36px;
      &:hover {
        opacity: 1;
      }
      span {
        padding-left: 10px;
      }
    }
    .isName {
      @include fontExtraBold;
      @include fontSize(18);
    }
    .isDesignation {
      @include fontExtraBold;
      @include fontSize(16);
      color: $coolGrey;
    }
    .departmentDetails {
      background-color: #f3f8fc;
      border-radius: 5px;
      border: 1px solid #eff0f4;
      padding: 10px;
      .department {
        span {
          &:first-child {
            color: #a3adbf;
            @include fontSize(14);
          }
          &:last-child {
            color: #4d5356;
            @include fontSize(16);
          }
        }
      }
      .contactInfo {
        span {
          color: #647677;
          @include fontRegular;
          display: flex;
          align-items: center;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
