.input-select,
.input-wrap {
  display: flex;
  flex-direction: column-reverse;
}

.input-select label,
.input-wrap label {
  color: #9d9f9e;
  font-size: 0.7rem;
  font-weight: 600;
  display: block;
  margin-bottom: 0.2rem;
}

.input-wrap input {
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 0.4rem 1rem;
  color: #0f1010;
  font-weight: 600;
  height: 3rem;
  border: none;
}

.input-wrap input:focus {
  outline: none;
}

.input-select label.flag-name {
  color: #0f1010 !important;
  font-weight: 800 !important;
}

.mobile_number .input-select label.flag-name {
  color: #fff !important;
}

.input-select label.flag-name {
  display: flex !important;
  align-items: center !important;
  font-size: 0.85rem !important;
}

.mobile_number div[class$="-menu"] {
  background-color: #0f1010 !important;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  background-color: #0bffa6;
  cursor: pointer;
}

.login-form .checkmark {
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.mobile_number .checkmark {
  position: absolute !important;
}
/* On mouse-over, add a grey background color */

.checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.login-form .checkmark:after {
  border: solid #0f1010;
  border-width: 0 2.5px 2.5px 0;
  left: 5.5px;
  top: 2px;
  height: 9px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}
.default-addr {
  display: flex;
  align-items: center;
  height: 2rem;
}
.default-addr label {
  color: #0f1010;
  font-weight: 700;
  font-size: 0.8rem;
}

.delivery-instructions {
  display: flex;
  flex-direction: column;
}

.delivery-instructions textarea {
  resize: none;
  width: 25rem;
  border-radius: 6px;
  padding: 1rem;
}

.note {
  color: #707070;
  font-weight: 600;
  font-size: 0.8rem;
}

.delivery-instructions label {
  color: #0f1010;
  font-size: 1.2rem;
  font-weight: 700;
}

.save-btn {
  padding: 0.4rem 3rem;
  background-color: #0f1010;
  color: #fff;
  font-size: 1rem;
  border-radius: 6px;
  height: 3rem;
  width: 20rem;
}
