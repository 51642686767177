@import "../../containers/variables";
@import "../../containers/flex";

.isCoporateCardContainer {
  min-height: 100vh;
  width: 100%;
  background: #dcede6;
  padding: 45px;
  .isCorporateWrapper {
    box-shadow: 0px 20px 20px #0000001a;
    border-radius: 25px;
    width: 100%;
    .cardCreationWrapper {
      width: 65%;
      position: relative;
      .isCardBackground {
        background: url(../../assets/images/corporate-card/background.png);
        background-position: 0 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
      .isLogo {
        position: absolute;
        top: 45px;
        left: 45px;
      }
      .isCorporateCard {
        height: 100%;
        .isCard {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          .isCardLogo {
            width: 600px;
            margin-top: 60px;
          }
          .imagePreview {
            .imagePreviewtxt {
              color: #f0f0f0;
              @include fontSize(18);
              @include fontSemiBold;
            }
          }
        }
      }
    }
    .cardCreationDetails {
      width: 35%;
      background-color: #ffffff;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      h2.title {
        @include fontSize(26);
        color: #22382d;
        @include fontExtraBold;
      }
      .subTitle {
        @include fontSize(22);
        color: #9d9f9e;
        @include fontSemiBold;
      }
    }
  }
}

.cardCreationDetails {
  .cardCreationForm {
    width: 85%;
    .formControl {
      label {
        color: #111212;
        @include fontSize(18);
        @include fontSemiBold;
        padding-bottom: 5px;
      }
      .isInputGroup {
        position: relative;
        input.isInputHover {
          background-color: #f0f0f0;
          border: 1px solid #f0f0f0;
          border-radius: 12px;
          min-height: 55px;
          padding: 10px 10px 10px 70px;
          width: 100%;
          -webkit-transition: 0.3s;
          transition: 0.3s;
          -moz-transition: 0.3s;
          &:hover {
            border: 1px solid #0bffa6;
          }
          &:focus,
          &:focus-within {
            background-color: #ffffff;
            border: 1px solid #0bffa6;
          }
          &::placeholder {
            color: #9d9f9e;
            @include fontSize(16);
            @include fontRegular;
          }
        }

        .noPad {
          padding-left: 10px !important;
        }
        span {
          position: absolute;
          left: 22px;
          top: 15px;
          &::after {
            content: "";
            background: #9d9f9e;
            position: absolute;
            width: 1px;
            height: 100%;
            right: -16px;
            top: 1px;
          }
        }
      }
    }
    .isCardButton {
      .isSubmitBtn {
        background-color: #111212;
        box-shadow: 0px 10px 10px #0000000d;
        min-height: 55px;
      }
    }
  }
}
.share-content {
  width: 35% !important;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .share-content {
    width: 95% !important;
  }
}
