.cart {
  margin: 0 5rem;
  position: relative;
}

.cart h3 {
  font-size: 1.8rem;
}

.hr-line {
  margin: 1.5rem 0;
  width: 100%;
  height: 1px;
  background-color: #9d9f9e80;
}

.cart .image-sec {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart .image-sec img {
  width: 10rem;
}

.cart-details {
  display: flex;
  align-items: center;
}

.cart .content-sec {
  margin-left: 2rem;
  width: 45%;
}

.content-sec .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-sec .title label {
  color: #0f1010;
  font-weight: bold;
  font-size: 1.2rem;
}

.style {
  color: #9d9f9e;
  margin: 0.3rem 0;
}

.price {
  color: #9d9f9e;
  font-size: 1.5rem;
  font-weight: bold;
}

.quantity button {
  background-color: #d3d3d3;
  color: #9d9f9e;
  font-size: bold;
  border: none;
  width: 2rem;
  height: 2rem;
  font-size: 1.4rem;
  cursor: pointer;
}
.quantity button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: relative;
  left: 2px;
}

.quantity button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  position: relative;
  right: 2px;
}

.quantity {
  border-radius: 6px;
  width: 6.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.quantity span {
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}
.total {
  color: #0f1010;
  font-size: 1.4rem;
  margin-top: 1.5rem;
  font-weight: 700;
}

.subtotal h3 {
  background-color: #e9f6f0;
  padding: 1.5rem 2.5rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.subtotal {
  position: absolute;
  right: 0;
  top: 0;
}

.subtotal .amts {
  background-color: #f5f5f5;
  padding: 1rem 2.5rem;
}

.amts p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.7rem 1rem;
  color: #0f1010;
  font-size: 1.1rem;
  font-weight: 700;
}

.cart p.apply {
  position: relative;
  margin-top: 2.5rem;
}

.apply input {
  background: #e8e3e3;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  border: none;
  width: 100%;
  height: 3rem;
  padding-left: 1.5rem;
}
.apply input::placeholder {
  color: #9d9f9e;
  font-size: 1rem;
  font-weight: 500;
}
.apply button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #46bdf9;
  color: #fff;
  border: none;
  height: 100%;
  width: 5rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 1rem;
}

.proceed {
  background-color: #0bffa6;
  color: #0f1010;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  width: stretch;
  border-radius: 6px;
  height: 3rem;
  margin: 1rem;
  cursor: pointer;
}

.add-ons {
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  min-width: 13rem;
  position: relative;
  margin-bottom: 2rem;
}

.add-ons .quantity {
  position: absolute;
  right: 3%;
  bottom: 18%;
}

.add-ons .price {
  font-weight: 600;
  font-size: 1.2rem;
  /* position: absolute; */
  display: flex;
  justify-content: flex-start;
  margin-left: 1rem;
  margin-bottom: 0.8rem;
  color: #0f1010;
}

.add-ons img {
  width: 10rem;
  height: 10rem;
  padding: 1rem;
  display: block;
  margin: auto;
}

.add-ons button.add-cart {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: none;
  background-color: #0f1010;
  color: #fff;
  padding: 1rem;
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}
.coupons {
  margin-left: 1.2rem;
}
.coupons li {
  cursor: pointer;
  color: #33333380;
}

.coupons span {
  text-decoration: underline;
  margin-left: 1rem;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .cart {
    margin: 0 2rem;
  }

  .subtotal h3 {
    padding: 1.3rem;
    font-size: 1.5rem;
  }

  .subtotal .amts {
    padding: 1rem;
  }
  .cart {
    display: flex;
    flex-direction: column;
  }

  .cart .w40 {
    width: 100%;
    position: relative;
  }

  .cart .w60 {
    width: 100%;
  }

  .cart-details {
    flex-direction: column;
  }

  .cart-details .image-sec,
  .cart-details .content-sec {
    width: 100%;
  }
  .cart-details .image-sec {
    margin-bottom: 1rem;
  }
  .cart-details .content-sec {
    margin-left: 0;
  }
}
