.thank_you {
  display: flex;
  min-height: 100vh;
}

.thank_you .left {
  background: url(../../../assets/thankyou_side.png);
  background-repeat: no-repeat;
  background-size: 100% 100vh;
}

.right {
  margin: 5rem auto;
}

.deliver {
  color: #fff;
  background-color: #0f1010;
  border-radius: 6px;
  height: 3rem;
  font-weight: bold;
  font-size: 1rem;
  width: 75%;
  margin: 2rem auto;
  display: block;
  cursor: pointer;
}

.thank_you ul {
  width: 75%;
  margin: 2rem auto;
}

.thank_you .title {
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left h3 {
  color: #0f1010;
  font-size: 2.5rem;
  position: absolute;
  top: 5rem;
  left: 5rem;
  width: 20rem;
}

.name,
.title label {
  color: #0f1010;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.title.del {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link {
  color: var(--primary-color);
  display: inline !important;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.addr {
  margin-bottom: 0.5rem;
}

.mobile strong {
  color: #0f1010;
}

.address {
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  border: #eaeaea 1px solid;
}

.address.selected {
  border: #0bffa6 1px solid;
}

.title button {
  color: #006432;
  font-weight: bold;
  border: 1px solid #0bffa6;
  border-radius: 29px;
  background-color: #bcfce5;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}

.privacy-policy h2 {
  color: #0bffa6;
  font-size: 3rem;
}

.privacy-policy p {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.privacy-policy h3 {
  color: #fff;
  font-size: 1.5rem;
  /* margin-bottom: 1.5rem; */
}

.privacy-policy ul {
  list-style: circle;
  margin: 10px 30px;
}
.termsNConditions ul {
  list-style: none;
  margin: 0px 30px;
}
.privacy-policy ul li {
  margin-bottom: 5px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .thank_you {
    display: flex;
    flex-direction: column;
  }

  .thank_you .left {
    min-height: 50vh;
    order: 2;
    position: relative;
    background-size: contain;
  }

  .thank_you .right {
    margin: 3rem auto;
  }
  .thank_you .left h3 {
    top: 2rem;
    font-size: 2.1rem;
  }

  .thank_you .title,
  .thank_you ul,
  .deliver {
    width: 100%;
    /* margin: 0 2rem; */
  }

  .thank_you .right {
    order: 1;
    padding: 2rem;
  }

  .thank_you .w50,
  .thank_you .w35,
  .note.w30 {
    width: 100%;
  }
}
