@import url(../src/components/styles/page1.css);
@import url(../src/components/styles/page2.css);
@import url(../src/components/styles/page3.css);
@import url(../src/components/styles/page5.css);
@import url(../src/components/app/styles/address.css);
@import url(../src/components/app/styles/cards.css);
@import url(../src/components/app/styles/cart.css);
@import url(../src/components/app/styles/thank_you.css);
@import url(../src/components/app/styles/login.css);
@import url(../src/components/form-components/form.scss);

ul {
  list-style: none;
}

.flex-col {
  flex-direction: column;
}
