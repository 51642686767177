.page3 {
  min-height: 100vh;
  width: 100%;
  display: flex;
}

.page3 div {
  position: relative;
  height: 100vh;
}

.page3 img {
  height: 120vh;
}
.page3 h3 {
  position: absolute;
  bottom: -3rem;
  left: 3rem;
  width: 50%;
  font-size: 3rem;
  color: var(--text-primary);
}

/* page 4 css */

.page4 {
  background: transparent linear-gradient(180deg, #202623 0%, #0f1010 100%) 0%
    0% no-repeat padding-box;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.page4 h3 {
  color: var(--text-primary);
  font-size: 4rem;
  width: 60%;
}

.page4 p {
  color: var(--text-gray);
  font-size: 1rem;
  width: 60%;
  margin-top: 2rem;
  line-height: 1.8rem;
}

/* page6 css */

.page6 {
  background: transparent linear-gradient(180deg, #202623 0%, #0f1010 100%) 0%
    0% no-repeat padding-box;
  /* min-height: 100vh; */
  padding: 5rem;
}
.page6 h3 {
  color: var(--text-primary);
  font-size: 3rem;
}

.page6 p {
  color: var(--text-gray);
  font-size: 1rem;
  margin-top: 1rem;
  line-height: 1.8rem;
}

.page6 .quote {
  background: url(../../assets/bg_quote.svg) no-repeat;
  background-size: contain;
  width: 28rem;
  height: 25rem;
  padding: 3rem;
}

.quote img:first-child {
  width: 5rem;
}

.icon {
  width: 5rem;
}

.quote label {
  color: var(--text-primary);
  font-size: 1.2rem;
  display: block;
  text-align: right;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.quote span {
  color: var(--text-gray);
}

.page6 .footer {
  /* padding-top: 4rem; */
}

.socials {
  margin-top: 1rem;
}

.socials img {
  width: 2rem;
}

.footer label {
  color: var(--text-primary);
  font-size: 1.5rem;
  display: block;
  margin-bottom: 1.5rem;
  position: relative;
  text-align: center;
}

.footer label::after {
  position: absolute;
  content: "";
  background: transparent linear-gradient(94deg, #93fcde 0%, #20b8fb 100%) 0% 0%
    no-repeat padding-box;
  width: 2.5rem;
  height: 2px;
  border-radius: 10px;
  bottom: -5px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.footer li {
  margin-bottom: 0.4rem;
  text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .page3 img.left {
    display: none;
  }

  .page3 h3 {
    bottom: 3rem;
    font-size: 2rem;
  }

  .page4 h3 {
    font-size: 2.5rem;
  }
  .page3 .w50 {
    width: 100%;
  }

  .logo-wrap .logo img {
    width: 9rem;
    height: 2.3rem;
  }

  .page3 img.w100 {
    height: 100vh;
  }

  .socials img {
    width: 1.5rem;
  }

  .page4,
  .page5 {
    flex-direction: column;
  }

  .page4 {
    padding: 4rem;
  }

  .page4 .w50,
  .page4 h3,
  .page4 p {
    width: 100%;
  }

  .page4 img {
    width: 20rem;
  }

  .page4 .ml5 {
    margin-left: 0;
  }

  .page6 .flex-between {
    flex-direction: column;
  }

  .page6 {
    padding: 2rem;
  }
  .page6 h3 {
    font-size: 2rem;
  }
  .page6 .quote {
    margin-bottom: 4rem;
    margin-left: 0rem;
    width: 20rem;
  }

  .quote img:first-child {
    width: 2.3rem;
  }

  .icon {
    width: 4rem;
  }

  .quote .flex-end {
    justify-content: space-between;
    margin-right: 0;
    margin-top: 3.5rem;
  }
  .logo-wrap {
    margin-bottom: 2rem;
    scale: 1.2;
    zoom: 1.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo-wrap .logo img {
    left: -0.6rem;
  }

  .page6 .footer label {
    margin-top: 2rem;
  }
  .page4 {
    min-height: 50vh;
  }
}
