@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url(./App.css);
body {
  font-family: "Nunito Sans", sans-serif;
  overflow-x: hidden;
}

#root {
  --bg-primary: #202623;
  --bg-secondary: #0f1010;
  --primary-color: #0bffa6;
  --text-gray: #d8d8d8;
  --text-primary: #0bffa6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* display */

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gap1 {
  gap: 1rem;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gap2 {
  gap: 2rem;
}
.gap5 {
  gap: 5rem;
}

.v-align-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

button:disabled {
  opacity: 0.6;
  cursor: auto;
}
/* display -- end */
.cursor {
  cursor: pointer;
}

.mAuto {
  margin: auto;
}
.m1 {
  margin: 1rem;
}

.m2 {
  margin: 2rem;
}
.m3 {
  margin: 3rem;
}
.m4 {
  margin: 4rem;
}
.m5 {
  margin: 5rem;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}
.p3 {
  padding: 3rem;
}
.p4 {
  padding: 4rem;
}
.p5 {
  padding: 5rem;
}
.mlHalf {
  margin-left: 0.5rem;
}
.ml1 {
  margin-left: 1rem;
}

.ml2 {
  margin-left: 2rem;
}
.ml3 {
  margin-left: 3rem;
}
.ml4 {
  margin-left: 4rem;
}
.ml5 {
  margin-left: 5rem;
}

.mbHalf {
  margin-bottom: 0.5rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}
.mb3 {
  margin-bottom: 3rem;
}
.mb4 {
  margin-bottom: 4rem;
}
.mb5 {
  margin-bottom: 5rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}
.pb3 {
  padding-bottom: 3rem;
}
.pb4 {
  padding-bottom: 4rem;
}
.pb5 {
  padding-bottom: 5rem;
}

.mt0 {
  margin-top: 0rem !important;
}

.mtHalf {
  margin-top: 0.5rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}
.mt3 {
  margin-top: 3rem;
}
.mt4 {
  margin-top: 4rem;
}
.mt5 {
  margin-top: 5rem;
}

.mr1 {
  margin-right: 1rem;
}

.mr2 {
  margin-right: 2rem;
}
.mr3 {
  margin-right: 3rem;
}
.mr4 {
  margin-right: 4rem;
}
.mr5 {
  margin-right: 5rem;
}

.w5 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}
.w45 {
  width: 45%;
}
.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}
.w65 {
  width: 65%;
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w85 {
  width: 85%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.container {
  padding-left: 5rem;
  padding-right: 5rem;
}

.p-relative {
  position: relative;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 40px 0;
  flex-wrap: wrap;
  max-width: 320px;
  justify-content: center;
}
.social-icons img {
  cursor: pointer;
}

p.desc {
  font-size: 1rem;
  font-weight: 400;
  max-width: 80%;
  text-align: center;
}
.super-admin .react-datepicker-wrapper {
  width: inherit;
}
.super-admin button {
  outline: none;
  border: none;
}

button:disabled {
  opacity: 0.5;
}

.refund,
.about-us {
  justify-content: flex-start;
}
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  html {
    font-size: 11px;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 19px;
  }
}
