@import "../../containers/variables";
@import "../../containers/reset";
@import "../../containers/flex";

.iconFarward {
  background-color: $panelBgColor;
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
}

.iconFarward.date {
  padding: 10px;
}

.isDashboard {
  .isSuperAdminDashboard {
    .leftPanel {
      width: 30%;
    }
    .rightPanel {
      width: 68%;
    }
  }
}

.isSuperAdminDashboard {
  .isCard {
    background-color: $panelBgColor;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 10px 20px #c4c8d066;
    margin-bottom: 20px;
    .isCountSection {
      .isLabel {
        color: #959595;
      }
      .isCount {
        @include fontExtraBold;
        @include fontSize(25);
        color: #202623;
        padding-top: 4px;
      }
      img {
        margin-right: 20px;
      }
    }
    .isToggleSection {
      margin-top: 25px;
      width: 100%;
      background-color: #eff1f5;
      border-radius: 15px;
      .isToggleButton {
        width: 33.33%;
        padding: 11px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        -moz-transition: 0.3s;
        cursor: pointer;
        .isLabel {
          @include fontSemiBold;
          @include fontSize(14);
          color: #818181;
          padding-top: 6px;
        }
        .isCount {
          @include fontExtraBold;
          @include fontSize(22);
          color: #323f3a;
          padding-top: 4px;
        }
      }
      .isToggleButton.active {
        background-color: #145ede;
        border-radius: 15px;
        .isLabel {
          color: #ffffff;
        }
        .isCount {
          color: #ffffff;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .isDashboardGraphs {
    .isCountSection {
      .isLabel {
        color: #959595;
      }
      .isCount {
        @include fontExtraBold;
        @include fontSize(25);
        color: #202623;
        padding-top: 4px;
      }
      img {
        margin-right: 20px;
      }
      img.iconAnimee {
        transition: transform 0.3s ease-out;
        cursor: pointer;
        &:hover {
          transform: rotate(45deg);
        }
      }
    }
    .noOfTxnGraph {
      min-height: 350px;
      background-color: #ffffff;
      padding: 25px 15px;
      border-radius: 20px;
      box-shadow: 0px 10px 20px #c4c8d066;
    }
  }
  .lowerSection {
    .isTable {
      width: 75%;
    }
    .signUpsCount {
      width: 25%;
      .SignUpSection {
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 10px 20px #c4c8d066;
        padding: 15px;
        min-height: 100%;
        .total {
          margin-top: 0;
          color: #9d9f9e;
          @include fontSize(25);
          @include fontSemiBold;
        }
        .signUp {
          color: #0f1010;
          @include fontSize(30);
        }
        .isCount {
          color: #1145ee;
          @include fontSize(42);
          @include fontExtraBold;
        }
      }
    }
  }
}

.isOrdersDataTable {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 10px 20px #c4c8d066;
  .isOrdersTitle {
    padding: 20px;
    h3.title {
      color: #272d3b;
      @include fontExtraBold;
      @include fontSize(32);
    }
  }
  table {
    thead,
    tbody {
      tr {
        th,
        td {
          vertical-align: middle;
          padding: 10px;
          text-align: center;
        }
      }
    }
    thead {
      tr {
        th {
          background-color: #e2fff4;
          color: #272d3b;
          @include fontExtraBold;
        }
      }
    }
    tbody {
      tr {
        border-top: 1px solid rgba($color: #272d3b, $alpha: 0.15);
        td {
          color: #272d3b;
          @include fontSemiBold;
          &:first-child {
            text-align: left;
          }
          button.btn {
            min-height: 28px;
            border-radius: 20px;
            border: none;
            @include fontSemiBold;
            text-transform: capitalize;
          }
          button.btn.btn-success {
            background-color: #9ff6d6;
            color: #00633f;
          }
          button.btn.btn-warning {
            background-color: #ffcfc1;
            color: #ff4913;
          }
          button.btn {
            cursor: text;
            &:hover {
              opacity: 1;
            }
          }
        }
        &:first-child {
          border-top: none;
        }
      }
      tr.isSelected {
        td {
          background-color: #e9eff8;
        }
        border-top: none;
      }
    }
  }
}

// .isPositionBackground {
//     position: absolute;
//     .isBackground {
//         background-color: #DCEDE6;
//         height: 200px;
//         position: relative;
//         img {
//             position: absolute;
//             right: 0;
//             top: 0;
//         }
//     }
// }

.isCommonDataTable {
  .isOrdersDataTable {
    table {
      thead,
      tbody {
        tr {
          th,
          td {
            vertical-align: middle;
            padding: 15px 10px;
            text-align: center;
          }
        }
      }
      thead {
        tr {
          th {
            background-color: #e2fff4;
            color: #272d3b;
            @include fontExtraBold;
            &:first-child {
              border-top-left-radius: 20px;
            }
            &:last-child {
              border-top-right-radius: 20px;
            }
          }
        }
      }
      tbody {
        tr {
          td.isSelect {
            text-align: left;
            [class*="css-"][class*="-control"] {
              border-radius: 20px;
              border-color: #dfd8d8;
              background-color: #f3f3f3;
            }
          }
          td {
            text-align: center;
          }
          &:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: 20px;
              }
              &:last-child {
                border-bottom-right-radius: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.isOrdersFiler {
  .isOrdersBox {
    padding: 15px;
    width: 175px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 10px 20px #c4c8d066;
    margin-right: 20px;
    margin-bottom: 16px;
    .isLabel {
      color: #818181;
      @include fontSemiBold;
      padding-top: 5px;
    }
    .isCount {
      color: #2e353e;
      @include fontExtraBold;
      padding-top: 4px;
      @include fontSize(25);
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .isOrdersBox.active {
    background-color: #145ede;
    .isLabel {
      color: #ffffff;
    }
    .isCount {
      color: #ffffff;
    }
  }
}

.footerNote {
  color: #9d9f9e;
  @include fontSemiBold;
  .btn.btn-dark {
    background-color: #0f1010;
    border: none;
    color: #ffffff;
  }
}

.isTotalDispatch {
  .totalCardsDispatch {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 15px 10px #e3e3e3;
    width: 25%;
    .totalCount {
      margin-left: 20px;
      .isLabel {
        color: #828282;
        @include fontSemiBold;
      }
      .isCount {
        color: #0f1010;
        @include fontExtraBold;
        @include fontSize(26);
        padding-top: 4px;
      }
    }
  }
}

.isUsers {
  .totalUsers {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 15px 10px #e3e3e3;
    .totalCount {
      margin-left: 20px;
      .isLabel {
        color: #828282;
        @include fontSemiBold;
      }
      .isCount {
        color: #0f1010;
        @include fontExtraBold;
        @include fontSize(26);
        padding-top: 4px;
      }
    }
    .isToggleUsers {
      margin-left: 45px;
      .itemList {
        padding: 15px;
        border-radius: 16px;
        .isUsers {
          margin-left: 12px;
          .isLabel {
            @include fontSemiBold;
          }
          .isCount {
            @include fontExtraBold;
            @include fontSize(18);
            padding-top: 4px;
          }
        }
        &:last-child {
          margin-left: 16px;
        }
        img {
          border-radius: 16px;
        }
      }
      .ActiveItems.itemList {
        background-color: #ddfff3;
        border: 1px solid #0dd58c;
        .isUsers {
          .isLabel {
            color: #00744b;
          }
          .isCount {
            color: #00744b;
          }
        }
      }
      .inActiveItems.itemList {
        background-color: #fff1f1;
        border: 1px solid #fa5454;
        .isUsers {
          .isLabel {
            color: #b40000;
          }
          .isCount {
            color: #ff3939;
          }
        }
      }
    }
  }
}
