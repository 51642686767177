.outline-btn {
  padding: 0.6rem 2rem;
  color: var(--primary-color);
  border-color: var(--primary-color);
  border: 2px solid;
  background: transparent;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 6px;
}

.page-1 {
  min-height: 100vh;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header {
  display: flex;
  justify-content: space-between;
}

ul li {
  color: var(--text-gray);
  cursor: pointer;
}

nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
}

aside .main {
  color: rgb(11, 255, 166);
  /* background: rgb(11,255,166);
background: linear-gradient(90deg, rgba(11,255,166,1) 0%, rgba(11,255,166,1) 40%, rgba(255,255,255,1) 100%); */
  font-size: 3.5rem;
  font-weight: 800;
}

aside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
}

aside .title,
span.title {
  color: #fff;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

aside .note {
  color: var(--text-gray);
  font-size: 1rem;
  width: 70%;
  margin-top: 1rem;
}

aside .more-info {
  padding: 0.6rem 2rem;
  color: var(--primary-color);
  border-color: var(--primary-color);
  border: 2px solid;
  background: transparent;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 6px;
  width: 10rem;
  margin-top: 3rem;
}
.img-sec {
  position: relative;
}
.img-sec img {
  position: absolute;
  right: 5%;
  top: -2rem;
  width: 70%;
  zoom: 0.8;
  scale: 0.8;
}
.logo img {
  width: 12rem;
  height: 3rem;
  position: relative;
  left: -2rem;
}

.page-1 footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--bg-secondary);
  padding: 2.5rem 3.5rem;
  border-top: 1px solid #7070707a;
  padding-top: 3.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.page-1 footer .item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-1 footer .item div {
  display: flex;
  margin-left: 1.5rem;
  flex-direction: column;
}

.page-1 footer .item div span:first-child {
  color: rgb(11, 255, 166);
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.page-1 footer .item div span:last-child {
  color: var(--text-gray);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.page-1 footer .line {
  width: 1.5px;
  height: 4rem;
  left: 35%;
}

.line {
  position: relative;
}

.line::after {
  content: "";
  position: absolute;
  left: 0px;
  width: 1.5px;
  top: -1.5rem;
  height: 3rem;
  background: transparent linear-gradient(360deg, #ffffff 0%, #0f1010 100%) 0%
    0% no-repeat padding-box;
}
.line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1.5rem;
  width: 1.5px;
  height: 3rem;
  background: transparent linear-gradient(180deg, #ffffff 0%, #0f1010 100%) 0%
    0% no-repeat padding-box;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  html {
    font-size: 11px;
  }

  nav li {
    display: none;
  }

  nav li:last-child {
    display: block !important;
  }

  aside {
    width: 100%;
  }

  aside .main {
    font-size: 2.5rem;
  }

  .img-sec {
    display: none;
  }

  .container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    margin: 3rem 0;
  }

  footer {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  footer {
    gap: 2rem;
    padding: 2rem;
  }

  footer div .line {
    margin-left: 0;
  }
  .line::after,
  .line::before {
    display: none;
  }

  footer .item div span:first-child {
    font-size: 1.5rem;
  }

  footer .item div span:last-child {
    font-size: 1rem;
  }

  footer .item img {
    width: 1rem;
  }
}
