.page5 {
  background: #dcefe8;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.page5 h3 {
  color: #0f1010;
  font-size: 3rem;
  width: 65%;
}

.page5 p {
  color: #333333;
  font-size: 1rem;
  width: 45%;
  margin-top: 2rem;
  line-height: 1.8rem;
}

.page5 form {
  margin-left: 5rem;
}

input:focus {
  outline: none;
}

.page5 form input {
  background: #f0f0f0 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 11px #0000000a;
  border-radius: 6px;
  padding: 1rem;
  height: 3rem;
  border: none;
  display: block;
  width: 25rem;
  margin-top: 1.5rem;
}

.page5 form button {
  background: transparent linear-gradient(99deg, #7fffd0 0%, #0bffa6 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 6px;
  padding: 1rem;
  height: 3rem;
  text-align: center;
  border: none;
  width: 25rem;
  margin-top: 1.5rem;
  color: #0f1010;
  font-weight: 600;
}

.page5 form input::placeholder {
  color: #9d9f9e;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .page5 {
    padding: 4rem;
  }
  .page5 .w50 {
    width: 100%;
  }

  .page5 .ml5,
  .page5 form {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page5 p {
    font-size: 1.3rem;
    width: 100%;
  }

  .page5 h3 {
    width: 100%;
    font-size: 2rem;
  }
  .page5 form input,
  .page5 form button {
    width: 20rem;
  }
  .page5 img {
    width: 20rem;
    margin-bottom: 3rem;
  }
}
