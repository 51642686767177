@import '../containers/variables';
@import '../containers/reset';
@import '../containers/flex';

.isSearchInput {
    position: relative;
    input.inputSearch {
        background-color: $panelBgColor;
        border-radius: 20px;
        min-height: 60px;
        border: none;
        box-shadow: 0px 9px 10px #0000000D;
        padding: 10px;
        padding-left: 38px;
        @include fontSemiBold;
    }
    img {
        position: absolute;
        left: 8px;
        top: 18px;
        cursor: pointer;
    }
}