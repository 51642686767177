.cards,
.cart {
  position: relative;
  top: 5rem;
}
.cards h3 {
  color: #0bffa6;
  text-align: center;
  font-size: 2.5rem;
}

.cards p {
  color: #0f1010;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.kobble-cards {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 3rem;
}

.kobble-cards p {
  font-size: 1.4rem;
  font-weight: bolder;
}

.kobble-cards .card-img {
  width: 18rem;
}

.cards p.happy {
  color: #d4d4d4;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.happy img {
  margin-right: 0.4rem;
}

.cards .flex-end {
  margin-right: 10rem;
}

.cards button {
  border: none;
  color: #0f1010;
  font-weight: 700;
  font-size: 1rem;
  width: 7rem;
}

.login-btn img {
  width: 0.6rem !important;
}

.cards .login-btn {
  justify-content: space-evenly;
  margin-right: 2rem;
}

.back {
  background: none;
  display: flex;
  align-items: center;
  margin: 0;
}

.back img {
  margin-right: 1rem;
}
.rotate {
  transform: rotate(180deg);
}
.out-of-stock {
  position: absolute;
  left: 1.6rem;
  padding: 0.5rem 1rem;
  top: 0;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: red;
  border-bottom-right-radius: 10px;
  color: white;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .kobble-cards .card-img {
    width: 10rem;
  }

  .kobble-cards {
    flex-wrap: wrap;
  }

  .kobble-cards ~ .flex-end {
    justify-content: center;
    margin-right: 0;
    flex-direction: column;
  }

  .cards .d-flex {
    align-items: center;
    margin-top: 1rem;
  }

  .cards .login-btn {
    margin: 0 !important;
  }
}
